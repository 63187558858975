<template>
  <v-container id="farmaco-info" fluid>
    <!-- TÍTULO  -->
    <v-row
      justify="center"
      class="sticky background-gradient top-title z-index-99"
    >
      <v-col cols="12" md="10" align-self="center">
        <page-top-title>
          <template v-slot:toptitle-icon>mdi-dna</template>
          <template v-slot:toptitle-text>
            {{ geneData.symbol }} | {{ geneData.name }}</template
          >
        </page-top-title>
      </v-col>
      <v-col cols="12" md="2" align-self="center">
        <v-row>
          <v-btn
            color="primary"
            :disabled="overlay"
            :loading="overlay"
            class=""
            medium
            :href="`https://mylogygenomics.com/?p=${geneData.wp_id}`"
          >
            <v-icon class="mt-n1 ml-n2 mr-2">mdi-currency-usd</v-icon>
            {{ $t("buy_pdf") }}
          </v-btn>
          <customer-portal-button v-if="hide" />
        </v-row>
      </v-col>
    </v-row>

    <!-- Mobile index indice movil -->
    <v-bottom-sheet v-model="sheet" inset>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="accent"
          dark
          v-bind="attrs"
          v-on="on"
          fab
          id="index-button"
          class="d-md-none"
        >
          <v-icon color="primary">mdi-format-list-bulleted-type</v-icon>
        </v-btn>
      </template>
      <v-sheet class="text-center" height="100%">
        <v-btn class="mt-6" text color="error" @click="sheet = !sheet">
          {{ $t("Cerrar") }}
        </v-btn>
        <v-list dense nav>
          <scrollactive
            active-class="active"
            :offset="80"
            :modifyUrl="false"
            :scrollOffset="100"
            :duration="800"
            bezier-easing-value=".5,0,.35,1"
            class="my-nav"
          >
            <v-list-item class="ml-2 scrollactive-item" link href="#basic_info">
              <a> 1. {{ $t("wgpgx_basic_info", "en") }}</a>
            </v-list-item>
            <v-list-item
              v-for="(section, index) in sectionsWithContent"
              :key="section"
              class="ml-2 scrollactive-item"
              link
              :href="'#' + section"
            >
              <a> {{ index + 2 }}. {{ $t("wgpgx_" + section, "en") }}</a>
            </v-list-item>
          </scrollactive>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>

    <v-row justify="center" v-if="geneData.id != null">
      <v-col cols="12" md="8" lg="8">
        <v-content class="full-height gradient-bucle">
          <section key="basic_info" id="basic_info">
            <v-card class="pa-0" rounded="lg">
              <v-card-title class="solid-heading rounded-lg py-3">
                <div class="card-title white--text mb-n2">
                  1. {{ $t("wgpgx_basic_info", "en") }}
                </div>
              </v-card-title>
              <v-card-text>
                <div class="basic-info">
                  <strong class="px-2">{{ $t("wgpgx_symbol", "en") }}:</strong>
                  <vue-markdown :source="geneData['symbol']" />
                </div>
                <div class="basic-info">
                  <strong class="px-2">{{ $t("wgpgx_name", "en") }}:</strong>
                  <vue-markdown :source="geneData['name']" />
                </div>
                <div class="basic-info">
                  <strong class="px-2"
                    >{{ $t("wgpgx_alternative_names", "en") }}:</strong
                  >
                  <vue-markdown :source="geneData['alternative_names']" />
                </div>

                <div class="basic-info">
                  <strong class="px-2"
                    >{{ $t("wgpgx_alternative_symbols", "en") }}:</strong
                  >
                  <vue-markdown :source="geneData['alternative_symbols']" />
                </div>

                <vue-markdown :source="geneData['codes']" />
                <vue-markdown :source="geneData['gene']" />
              </v-card-text>
            </v-card>
          </section>
          <section
            v-for="(section, index) in sectionsWithContent"
            :key="section"
            :id="section"
          >
            <v-card class="pa-0" rounded="lg">
              <v-card-title class="solid-heading rounded-lg py-3">
                <div class="card-title white--text mb-n2">
                  {{ index + 2 }}. {{ $t("wgpgx_" + section, "en") }}
                </div>
              </v-card-title>
              <v-card-text>
                <vue-markdown
                  :class="{ 'hide-content': hide }"
                  :source="geneData[section]"
                  class="px-2  py-4"
                />
              </v-card-text>
            </v-card>
          </section>
        </v-content>
      </v-col>
      <!-- NAVEGADOR LATERAL -->
      <v-col cols="4" md="4" lg="3" class="d-none d-md-block">
        <v-card
          max-height="90vh"
          flat
          class="nav-card py-0 mt-12"
          elevation="0"
        >
          <v-navigation-drawer permanent width="100%">
            <v-list dense nav>
              <scrollactive
                active-class="active"
                :offset="300"
                :modifyUrl="false"
                :scrollOffset="0"
                :duration="800"
                bezier-easing-value=".5,0,.35,1"
                class="my-nav"
              >
                <v-list-item
                  class="ml-2 scrollactive-item"
                  link
                  href="#basic_info"
                >
                  <a> 1. {{ $t("wgpgx_basic_info", "en") }}</a>
                </v-list-item>
                <v-list-item
                  v-for="(section, index) in sectionsWithContent"
                  :key="section"
                  class="ml-2 scrollactive-item"
                  link
                  :href="'#' + section"
                >
                  <a> {{ index + 2 }}. {{ $t("wgpgx_" + section, "en") }}</a>
                </v-list-item>
              </scrollactive>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from "@/services/Strapi.js";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import VueMarkdown from "vue-markdown-render";
import CustomerPortalButton from "@/components/mylogy/CustomerPortalButton.vue";
export default {
  name: "ExplorerGeneDetail",
  props: ["id"],
  components: {
    PageTopTitle,
    VueMarkdown,
    CustomerPortalButton
  },
  data() {
    return {
      sheet: false,
      geneSections: [
        "rna",
        "protein",
        "function",
        "related_diseases",
        "related_drugs",
        "animal_models",
        "allelic_variants",
        "allele_nomenclature",
        "selected_snps",
        "evolution",
        "genomics_and_pharmacogenomics",
        "drug_gene_interactions",
        "authors",
        "references"
      ],
      hide: true,
      geneData: {
        symbol: null,
        name: null,
        alternative_names: null,
        alternative_symbols: null,
        codes: null,
        gene: null,
        rna: null,
        protein: null,
        function: null,
        related_diseases: null,
        related_drugs: null,
        animal_models: null,
        allelic_variants: null,
        allele_nomenclature: null,
        selected_snps: null,
        evolution: null,
        genomics_and_pharmacogenomics: null,
        drug_gene_interactions: null,
        authors: null,
        references: null,
        toc: null,
        wp_id: null,
        id: null
      }
    };
  },
  computed: {
    sectionsWithContent() {
      let self = this;
      return self.geneSections.filter(gs => {
        return self.geneData[gs] != null;
      });
    }
  },
  mounted() {
    this.getEPGGeneData();
  },
  methods: {
    getEPGGeneData() {
      let self = this;
      Api.get(`genes/${this.id}/kb`).then(function(resp) {
        self.hide = resp.data.hide;
        self.geneData = resp.data.epg;
      });
    }
  }
};
</script>

<style scoped>
.basic-info {
  display: flex;
}

.v-card__title {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
#index-button {
  position: fixed;
  bottom: 40px;
  right: 10px;
  z-index: 3;
}
</style>
